<script>
import i18n from '@/shared/plugins/vue-i18n';
import BaseButton from '@/shared/components/BaseButton.vue';
import { server } from '@/const';
import { destroyToken, getToken } from '@/shared/services/jwt.service';
import Notify from '@/shared/utils/notify';
import { utils, writeFile, read } from 'xlsx';

export default {
  components: {
    BaseButton,
  },
  data() {
    return {
      excelDownloaded: false,
      students: [],
    };
  },
  methods: {
    downloadExcel() {
      const wb = utils.book_new();
      const worksheet = utils.json_to_sheet([{
        Nume: '',
        Prenume: '',
        Email: '',
      }]);

      utils.book_append_sheet(wb, worksheet, 'Students');
      // write the workbook object to a file
      writeFile(wb, 'Students.xlsx');
      this.excelDownloaded = true;
    },
    onUpload(e) {
      const file = e.currentTarget.files[0] ? e.currentTarget.files[0] : {};
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;

        if (sheets.length > 0) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          if (rows.length > 0) {
            if (!rows[0].Nume || !rows[0].Prenume || !rows[0].Email) {
              Notify('warning', i18n.t('ERRORS.FILE_FORMAT'));
              this.students = [];
              //   toast.error()
            } else {
              for (let i = 0; i < rows.length; i += 1) {
                rows[i] = {
                  first_name: rows[i].Nume.trim(),
                  last_name: rows[i].Prenume.trim(),
                  email: rows[i].Email.trim(),
                };
              }
              this.students = rows;
            }
          } else {
            Notify('warning', i18n.t('ERRORS.FILE_EMPTY'));
            this.students = [];
          }
        } else {
          this.students = [];
        }
      };
      reader.readAsArrayBuffer(file);
    },
    importStudents() {
      const addStudentPath = '/WebAddUser.php';
      for (let i = 0; i < this.students.length; i += 1) {
        const data = new FormData();
        data.append('name', `${this.students[i].first_name} ${this.students[i].last_name}`);
        /*    */
        data.append('username', this.students[i].email);
        data.append('role', 'student');
        data.append('active', true);
        data.append('group_ids', parseInt(this.$route.params.parent_id, 10));
        fetch(server + addStudentPath, {
          method: 'POST',
          headers: {
            WebToken: getToken(),
          },
          body: data,
        })
          .then((response) => response.json())
          .then((json) => {
            if (json.status === 'error') {
              if (json.error.includes('Wrong token')) {
                this.$router.push({ name: 'login' });
                destroyToken();
              } else {
                Notify('warning', json.error);
              }
            }
          });
      }
      this.$router.push(`/management/student/students-results/${this.$route.params.parent_id}`);
    },
  },
  beforeMount() {
  },
};
</script>
<template lang="html">
  <div>
    <b-row>
      <b-col class="add-news-title" style="padding: 0;">
        {{ $t("DEPARTAMENTS.IMPORT_STUDENTS") }}
      </b-col>
    </b-row>
    <b-row v-if="excelDownloaded === false">
      <b-col md="12">
        <div
          v-on:click="downloadExcel()">
          <BaseButton
            :variant="'secondary'"
            style="width: 100%; text-align: center;justify-content: center"
          >
            {{ $t('GENERAL.DOWNLOAD_EXCEL') }}
          </BaseButton>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="excelDownloaded === true && students.length === 0">
      <b-col md="12">

        <div>
          <input type="file" style="display: none"
                 class="hidden"
                 accept=".xlsx"
                 id="upload-pdf"
                 @change="onUpload"/>
          <label class="btn-primary" for="upload-pdf" style="width: -webkit-fill-available">
            <unicon fill="#ffffff" height="24" width="24" name="cloud-upload"/>
            {{ $t('CHAT.UPLOAD_FILE') }}
          </label>
        </div>
      </b-col>
    </b-row>

    <b-row class="mb-4" v-for="(item,i) in students" :key="i">
      <b-col lg="4" class="ps-0">
        <div class="form-floating">
          <input type="text"
                 class="form-control floatingInput"
                 :value="item.first_name"
                 disabled
                 :placeholder="$t('EMPLOYEE.FIELDS.FIRST_NAME')"
          >
          <label for="floatingInput">
            {{ $t('EMPLOYEE.FIELDS.FIRST_NAME') }}</label>
        </div>
      </b-col>
      <b-col lg="4" class="pe-0">
        <div class="form-floating">
          <input type="text"
                 disabled
                 class="form-control floatingInput"
                 :value="item.last_name"
                 :placeholder="$t('EMPLOYEE.FIELDS.LAST_NAME')"
          >
          <label for="floatingInput">
            {{ $t('EMPLOYEE.FIELDS.LAST_NAME') }}</label>
        </div>
      </b-col>
      <b-col lg="4" class="pe-0">
        <div class="form-floating">
          <input type="text"
                 class="form-control floatingInput"
                 :value="item.email"
                 disabled
                 :placeholder="$t('EMPLOYEE.FIELDS.EMAIL')"
          >
          <label for="floatingInput">
            {{ $t('EMPLOYEE.FIELDS.EMAIL') }}</label>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="students.length > 0">

      <b-col style="padding: 0">
        <BaseButton
          :callback="importStudents"
          :callback-params="1"
          :variant="'primary'"
          style="float: right;"
        >
          {{ $t('GENERAL.SAVE') }}
        </BaseButton>
      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
.add-news-title {
  font-family: Nunito, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #22232F;
  margin-bottom: 30px;
}

.select-admin {
  margin-bottom: 16px;
}

.separator {
  height: 2px;
  border: 1px solid #D9DBE3;
  margin-bottom: 20px;
}

.add-news-subtitle {
  font-family: Nunito, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #22232F;
  margin-bottom: 16px;
}

.add-news-description {
  background: #FDFDFD;
  border: 1px solid #D9DBE3;
  box-sizing: border-box;
  box-shadow: 2px 2px 4px rgba(166, 171, 189, 0.25);
  border-radius: 8px;
  margin-bottom: 36px;
  width: 100%;
}

.container-button {
  position: relative;
}

.container-button button {
  position: absolute;
  right: 0;
}

.container {
  width: 100%;
  height: 100%;
  background: transparent;
  border: 0.5px solid #a3a8b1;
  padding: 12px;
  position: relative;
  border: 0.5px dashed #9A9FA7;
  box-sizing: border-box;
  filter: drop-shadow(2px 2px 4px rgba(166, 171, 189, 0.25));
  border-radius: 8px;
  margin-bottom: 30px;
}

</style>
